<template>
    <div class="p-5 mt-5">
        <!-- <p class="fw-bold">03SO24050311</p> -->
            <form class="row g-3 mb-3" id="frmPengajuan" @submit.prevent="saveData" autocomplete="off">
                <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">Masukan SO</label>
                <input type="text" class="form-control" id="inputPassword2" placeholder="Masukan PO" v-model="rec.ta_so" required>
                </div>
                <div class="col-auto">
                <label for="enty" class="visually-hidden">Masukan PO</label>
                <select class="form-select" id="enty" placeholder="entity" v-model="rec.ta_enty" disabled required>
                    <option value="01" selected>SBY</option>
                    <option value="02">SMG</option>
                </select>
                </div>
                <div class="col-auto">
                <button form="frmPengajuan" class="btn btn-success rounded" type="submit" :disabled="isFormInvalid"><i class="fa fa-save"></i> Data Pembelian</button>
                </div>
            </form>
            <div class="row">
                <div v-if="showDiv" class="col-6">
                    <button @click="copyToClipboard" class="btn btn-primary mb-5">Copy</button>
                    <p class="h5 fw-bold">Supplier : {{this.reco.nama}}</p>
                    <p class="h5 fw-bold">Manual Reff : {{this.reco.ref}}</p>

                    <p class="h5 fw-bold">Item :</p>
                    <ul class="mb-3">
                        <li v-for="(dt, index) in reci" :key="index">
                            <b>{{ dt.item }}</b> @ {{ dt.qty }}
                        </li>
                    </ul>
                </div>

            </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar2.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";


export default {

    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Approved',
    components: {
        Toolbar
    },
    data() {
        return {
            username : localStorage.username,
            userz : [],
            rec: {
            ta_so: '',
            ta_enty: '  ',},
            reci: [],
            reco : [],
            showDiv: false,

        }
    },
    methods : {
        saveData(event){
            var self = this;
            self.rec = {
                pfunction : 'show',
                so : self.rec.ta_so,
                ent : self.rec.ta_enty,
            }
            axios.post("purchase/ApiPurchase.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.reci = response.data.rec;
                    self.reco = response.data.recO;
                    self.showDiv = true;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        copyToClipboard() {
        const contentToCopy = this.generateCopyContent();

        const textarea = document.createElement('textarea');
        textarea.value = contentToCopy;
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand('copy');

        document.body.removeChild(textarea);

        alert('Copied to clipboard!');
        },

        generateCopyContent() {
        const nomerSo = `Nomer PO: *${this.rec.so}*\n`;
        const customerInfo = `Supplier: *${this.reco.nama}*\n`;
        const reff = `Manual Reff: *${this.reco.ref}*\n\n`;

        const orderInfo = this.reci.map(dt => `${dt.item} @ ${dt.qty}`).join('\n');


        // Combine all the information
        return `${nomerSo}${customerInfo}${reff}${orderInfo}`;
        },

    },
    filters: {
        toRp(value) {
        return `${Intl.NumberFormat("id-ID", { style: 'currency', currency: 'IDR' }).format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        },
        toRps(value) {
            return Intl.NumberFormat("id-ID", { style: 'currency', currency: 'IDR' }).format(value);
        }
    },
    watch: {
        'rec.ta_so': function(newVal) {
        if (newVal.startsWith('1POP')) {
            this.rec.ta_enty = '01';
        } else if (newVal.startsWith('03PO')) {
            this.rec.ta_enty = '02';
        } else {
            this.rec.ta_enty = '01';
        }
        },
    },
    computed :{

        isFormInvalid() {
            return !this.rec.ta_so || !this.rec.ta_enty;
        },

    },
    mounted(){
        this.screenHeight = window.innerHeight;

    }
};
</script>